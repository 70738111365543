import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "primereact/button";
import EzControlledDropDowntWithFloatingLabel from "../../../../common/EzForm/EzControlledDropDown/WithFloatingLabel";
import "./styles.css";
import { goBack } from "../../../../utils/gotoback";
import { loadingShow } from "../../../../services/PostAPI";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useStyles } from '../style';
import { basicDetail, datagetbyid, driverageDD, editMode, statusDD, typeDD } from "../CreateReservationsReservations/constant"
import { Box, Container, Grid, Typography } from "@mui/material";
import EzControlledInputWithFloatingLabel from "../../../../common/EzForm/EzControlledInput/WithFloatingLabel";
import { EzToast, TOAST_SEVERITY } from "../../../../common/EzToast";
import { useNavigate } from "react-router-dom";
import { APP_URLS } from "../../../../utils/constant";
import EzControlledCalendarWithFloatingLabel from "../../../../common/EzForm/EzControlledCalendar/WithFloatingLabel";
import { getFormBaseValidationRule } from "../../../../utils/form/form-utils";
import EzControlledCheckBoxWithLabel from "../../../../common/EzForm/EzControlledCheckBox/WithLabel";
import { ReservationsReservationsBreadcrumb } from "../../ReservationsReservations/CreateReservationsReservations/constant";
import { hourslist } from "../../../Location/CreateLocation/constant";
import EzControlledInputAreaWithFloatingLabel from "../../../../common/EzForm/EzControlledInputArea/WithFloatingLabel";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HeatPumpIcon from '@mui/icons-material/HeatPump';
import SpeedIcon from '@mui/icons-material/Speed';
import CardICON from '../../../../images/car-door.png'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { formatDates, formatDatesHyphan, formatDatesMMDDYYYYY } from "../../../../utils";
const CreateReservationsReservationsComponent = (props) => {
  const navigate = useNavigate();
  const {
    history,
    initCreateReservationsReservationsScreen,
    createReservationsReservations,
    searchReservationsReservations,
    editReservationsReservations,
    isLoading,
    isReservationsReservationsCreateSuccess,
    isReservationsReservationsCreateError,
    isReservationsReservationsDetailSuccess,
    isReservationsReservationsDetailError,
    isReservationsReservationsEditSuccess,
    isReservationsReservationsSearchSuccess,
    isReservationsReservationsSearchError,
    isEdit,
    isView,
    formFieldValueMap,
    shouldBlockNavigation,
    uploadImage,
    imageUploadResponse,
    isUploadImageSuccess,
    isUploadImageError,
    vehicleModelDD,
    isVehicleModelDDSuccess,
    isVehicleModelDDError,
    locationDD,
    vehicleCategoryDD
  } = props;
  console.log("check props of compoemnt", props);


  const classes = useStyles()

  const {
    control,
    formState: { errors, isDirty },
    watch,
    register,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", formFieldValueMap);
      return formFieldValueMap;
    }, [formFieldValueMap]),
  });
  let searchfiled = {
      "pickUpDate": new Date(),
      "dropOffDate": new Date(),
      "locationId": 0,
      "categoryId": 0,
      "pickUpTime": "10:00AM",
      "dropOffTime": "02:30PM",
      "driverAge": 2
  }
  const {

    handleSubmit: handleSubmit2,
    control: control2,
    register: register2,
    reset: resetForm2,
    formState: { errors: errors2, isDirty: isDirty2 },
    watch: watch2,
  } = useForm({
    defaultValues: useMemo(() => {
      console.log("check value come or not", searchfiled);
      return searchfiled;
    }, [searchfiled]),
  });
  
  let otherData = datagetbyid.Other;
  const [amountTotal, setAmountTotal] = useState(0);

  const [extraamountTotal, setExtraAmountTotal] = useState(0);
  const [damageTotal, setDamageTotal] = useState(0);
  const [additionalDriverTotal, setAdditionalDriverTotal] = useState(0);
  const [glassTiresTotal, setGlassTiresTotal] = useState(0);
  const [equipmentTotal, setEquipmentTotal] = useState(0); 
  const [outofProvinceTotal, setOutofProvinceTotal] = useState(0); 
  const [extraMileageTotal, setExtraMileageTotal] = useState(0); 

  const [other, setOther] = useState({}); //otherData
  const [paymentOption, setPaymentOption] = useState([]); //payOptionData
  const [damage, setDamage] = useState([]);//damageData
  const [additionalDriver, setAdditionalDriver] = useState([]);
  const [glassTires, setGlassTires] = useState([]); //glassTiresData
  const [equipment, setEquipment] = useState([]); //equipmentData
  const [outofProvince, setOutofProvince] = useState([]); //outofProvinceData
  const [extraMileage, setExtraMileage] = useState([]); //extraMileageData
  const [extraBoll, setExtraBoll] = useState(false);
  const [searchPayload, setSearchPayload] = useState({});
  const otherDis = datagetbyid.Other.filter(item => item.name === "Pay now discount");

  const navigateToReservationsReservationsScreen = () => {
    window.location.replace("/#" + APP_URLS.RESERVATIONS_RESERVATIONS.HOME)
  };

  const [inclusiveRate, setInclusiveRate] = useState(false)
  const handleCheckbox = (e) => {
    const { name, checked } = e.target;
    setInclusiveRate(checked)
  }

  const onSubmit2 = (data) => {
    let newData = data;
    newData["pickUpDate"] = formatDatesHyphan(data?.pickUpDate);
    newData["dropOffDate"] = formatDatesHyphan(data?.dropOffDate);

    const stringifiedData = {
      ...newData,
    }
    setSearchPayload(stringifiedData)
    searchReservationsReservations(stringifiedData);
  };
  useEffect(() => {

    if (isReservationsReservationsCreateSuccess || isReservationsReservationsEditSuccess) {
      reset();
    }
  }, [isReservationsReservationsCreateSuccess]);
  useEffect(() => {
    if (isReservationsReservationsSearchSuccess) {
      reset({
        ...formFieldValueMap,
      });
      handleVisualCalc(formFieldValueMap)
    }
  }, [isReservationsReservationsSearchSuccess]);
  useEffect(() => {
    initCreateReservationsReservationsScreen();
  }, []);


  const handleVisualCalc =(dataset) =>{
 
    let payOptionsDatas=
    [
      {id:1, name:"Pay Now", value:dataset?.payNowdiscountBeforeTax, discount:dataset?.payNowDiscountAmont},
      {id:2, name:"Pay Later", value:dataset?.payLater, discount:0 }
    ];
  
  let payOptionData = payOptionsDatas;
  payOptionData = payOptionData.map((item, idx) => ({
    ...item,
    isChecked: idx === 0 ? true : false
  }))
  // Damage Data
  let damageData = dataset?.damageProtection ?? [];
  damageData = damageData.map((item, idx) => ({
    ...item,
    isChecked: idx === 0 ? true : false
  }))

  // Additional Driver
  let additionalDriverData = dataset.additionalDriver ?? [];
  additionalDriverData = additionalDriverData.map((item, idx) => ({
    ...item,
    isChecked: idx === 0 ? true : false
  }))

  // GlassTires
  let glassTiresData = dataset.glassTires ?? [];
  glassTiresData = glassTiresData.map((item, idx) => ({
    ...item,
    isChecked: idx === 0 ? true : false
  }))
  // GlassTires
  let equipmentData = dataset.equipment ?? [];
  equipmentData = equipmentData.map((item, idx) => ({
    ...item,
    isChecked:false
  }))
  // OutofProvince
  let outofProvinceData = dataset.outOfProvince ?? [];
  outofProvinceData = outofProvinceData.map((item, idx) => ({
    ...item,
    isChecked: idx === 0 ? true : false
  }))
  // ExtraMileage
  let extraMileageData = dataset.extraMileage ?? [];
  extraMileageData = extraMileageData.map((item, idx) => ({
    ...item,
    isChecked: idx === 0 ? true : false
  }))
  setPaymentOption(payOptionData);
  setOther(dataset);
  setAdditionalDriver(additionalDriverData);
  setAmountTotal(dataset?.totalAmount);
  setDamage(damageData);
  setGlassTires(glassTiresData);
  setEquipment(equipmentData);
  setOutofProvince(outofProvinceData);
  setExtraMileage(extraMileageData);
  }
  const onSubmit = (data) => {
    let newData = data;
    newData["userId"] = localStorage.getItem("userId");
    const po = paymentOption.filter(item => item.isChecked === true)[0] || null;
    const oop = outofProvince.filter(item => item.isChecked === true)[0] || null;
    const add = additionalDriver.filter(item => item.isChecked === true)[0] || null;
    const dpp = damage.filter(item => item.isChecked === true)[0] || null;
    const em = extraMileage.filter(item => item.isChecked === true)[0] || null;
    const gt = glassTires.filter(item => item.isChecked === true)[0] || null;
    const rel = equipment.filter(item => item.isChecked === true) || null;
    let eqpdata =  rel.map(item=>({
      eqId:item?.id,
      eqVal:item?.amount
    }))
    const stringifiedData = {
      "addId": add?.id,
      "addVal": add?.amount,
      "address": newData?.address,
      "airportConcessionFee": newData?.concessionFee,
      "airportFacilityChargeTotal": newData?.airportFacilityChargeTotal,
      "beforeTax": newData?.beforeTaxAmount,
      "cardExpiry": newData?.cardExpiry,
      "categoryId": searchPayload?.categoryId,
      "creditCardNumber": newData?.creditCardNumber,
      "cvv": newData?.cvv,
      "days": newData?.airportFacilityChargeTotalForDays,
      "dppId": dpp?.id,
      "dppVal": dpp?.amount,
      "driverAge": searchPayload?.driverAge,
      "dropOffDate": formatDatesMMDDYYYYY(searchPayload?.dropOffDate),
      "dropOffTime": searchPayload?.dropOffTime,
      "emId": em?.id,
      "emVal":em?.amount,
      "emailId": newData?.emailId,
      "fees": newData?.fee,
      "firstName": newData?.firstName,
      "flightDetails": newData?.flightDetails,
      "gtId": gt?.id,
      "gtVal": gt?.amount,
      "lastName": newData?.lastName,
      "locationAfterHourFee": newData?.locationAfterHourFee,
      "locationId": searchPayload?.locationId,
      "nameOnCard": newData?.nameOnCard,
      "netDailyRate": "",
      "oopId": oop?.id,
      "oopVal": oop?.amount,
      "payNow": po?.id,
      "phoneNumber": newData?.phoneNumber,
      "pickUpDate": formatDatesMMDDYYYYY(searchPayload?.pickUpDate),
      "pickUpTime": searchPayload?.pickUpTime,
      "remainderHours": "",
      "reservationEquipmentList": eqpdata ?? [],
      "reservationSearchRequest": JSON.stringify(searchPayload),
      "taxAmountDiscount":newData?.taxAmountDiscount,
      "totalAmount": amountTotal,
      "type": "",
    }

    isEdit
      ? editReservationsReservations({ id: data.id, data: stringifiedData })
      : createReservationsReservations(stringifiedData);

  };

  
  const getToastProps = () => {
    const toastTitle = "Please select category from list";
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle,
      shouldShowToast: false,
    };
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };
  const getTotalCheckedValue = (items) => {
    return items
      .filter(item => item.isChecked) 
      .reduce((sum, item) => sum + item.amount, 0); 
  };
  const handleTotalAmount = () => {

    const po = paymentOption.filter(item => item.isChecked === true)[0] || null;
    let totalValue = other.totalAmount;
  
  totalValue = totalValue - po?.discount ?? 0;

 
    const damValue = damage.find(item => item.isChecked === true)?.amount || 0;
    const ofpValue = outofProvince.find(item => item.isChecked === true)?.amount || 0;
    const adValue = additionalDriver.find(item => item.isChecked === true)?.amount || 0;
    const gtValue = glassTires.find(item => item.isChecked === true)?.amount || 0;
    const emValue = extraMileage.find(item => item.isChecked === true)?.amount || 0;
    const eqpValue = getTotalCheckedValue(equipment);

    const extTotal = (damValue + ofpValue + adValue + gtValue + emValue + eqpValue);
    setExtraAmountTotal(extTotal ?? 0 );
    setDamageTotal(damValue ?? 0 );
    setAdditionalDriverTotal(adValue ?? 0 );
    setGlassTiresTotal(gtValue ?? 0 );
    setEquipmentTotal(eqpValue ?? 0 ); 
    setOutofProvinceTotal(ofpValue ?? 0 ); 
    setExtraMileageTotal(emValue ?? 0 ); 
    const finalTotal = (totalValue + damValue + ofpValue + adValue + gtValue + emValue + eqpValue);
    setAmountTotal(finalTotal); 
  };
  
  useEffect(() => {
    handleTotalAmount();
  }, [other, damage, outofProvince, additionalDriver, glassTires, extraMileage, equipment, paymentOption]);
  
const handleExtra = () =>{
  setExtraBoll(!extraBoll);
}
  
  const handleRadio = (e, i) => {
    let { name, value, checked } = e.target;
    if (name === "payOption") {
      const updatedData = paymentOption.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item, isChecked: false }
      ))

      setPaymentOption(updatedData);
      const po = paymentOption.filter(item => item.isChecked === true)[0] || null;
      let totalValue = other.totalAmount;
    
    totalValue = totalValue - po.discount;
  
    setAmountTotal(totalValue);
    }
    if (name === "damage") {
      const updatedData = damage.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item, isChecked: false }
      ))
      setDamage(updatedData);
      
    }
    if (name === "outofProvince") {
      const updatedData = outofProvince.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item, isChecked: false }
      ))
      setOutofProvince(updatedData);
    }
    
    if (name === "additionalDriver") {
      const updatedData = additionalDriver.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item, isChecked: false }
      ))
      setAdditionalDriver(updatedData);
    }
    
    if (name === "glassTires") {
      const updatedData = glassTires.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item, isChecked: false }
      ))
      setGlassTires(updatedData);
    }

    if (name === "equipment") {
      const updatedData = equipment.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item }
      ))
      setEquipment(updatedData);
    }
    
    if (name === "extraMileage") {
      const updatedData = extraMileage.map((item, index) => (
        i === index ? { ...item, isChecked: checked } : { ...item, isChecked: false }
      ))
      setExtraMileage(updatedData);
    }

  }

  const renderPageHeaderSection = () => {
    return (
      <div className="make-relative sub-header ">
        <div className="maintitlediv">
          <div className="ml-3">
            <h4 className="pagetitle">Create Reservations</h4>
          </div>
          <div className="buttonarea">
            <Button
              tooltip={"Back to Reservations"}
              tooltipOptions={{ position: "left" }}
              label={"Back to Reservations"}
              // icon={"pi pi-arrow-left"}
              className={`p-button `}
              style={{ borderRadius: "10px" }}
              onClick={navigateToReservationsReservationsScreen}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Container maxWidth="lg">
      <Breadcrumb list={ReservationsReservationsBreadcrumb} />
      <EzToast {...getToastProps()} />
      <div className="containerbox" className={classes.bg}>
        {renderPageHeaderSection()}
        <div className="form-demo mrt30">

          <form onSubmit={handleSubmit2(onSubmit2)} className="p-fluid">

            <Grid container spacing={2}>
              <Grid item xs={2}>
                <EzControlledDropDowntWithFloatingLabel
                  name="locationId"
                  control={control2}
                  rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                  optionLabel={"name"}
                  optionValue={"id"}
                  options={locationDD}
                  isError={errors2['locationId']}
                  errorMsg={getFormErrorMessage('locationId')}
                  label="Location"
                  labelClassName={"ml-2"}
                />
              </Grid>
              <Grid item xs={2}>
                <EzControlledDropDowntWithFloatingLabel
                  name="categoryId"
                  control={control2}
                  rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                  optionLabel={"name"}
                  optionValue={"id"}
                  options={vehicleCategoryDD}
                  isError={errors2['categoryId']}
                  errorMsg={getFormErrorMessage('categoryId')}
                  label="Vehicle"
                  labelClassName={"ml-2"}
                />
              </Grid>

              <Grid item xs={2}>
                <EzControlledCalendarWithFloatingLabel
                  id="pickUpDate"
                  name="pickUpDate"
                  {...register2("pickUpDate")}
                  showTime={false}
                  maxDate={new Date(2090, 0, 1)}
                  dateFormat="yy/mm/dd"
                  control={control2}
                  isError={errors2["pickUpDate"]}
                  errorMsg={getFormErrorMessage("pickUpDate")}
                  label="Pickup Date"
                  rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                />
              </Grid>
              <Grid item xs={2}>
                <EzControlledDropDowntWithFloatingLabel
                  name="pickUpTime"
                  control={control2}
                  rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                  optionLabel={"name"}
                  optionValue={"id"}
                  options={hourslist}
                  isError={errors2['pickUpTime']}
                  errorMsg={getFormErrorMessage('pickUpTime')}
                  label="Pickup Time"
                  labelClassName={"ml-2"}
                />
              </Grid>
              <Grid item xs={2}>
                <EzControlledCalendarWithFloatingLabel
                  id="dropOffDate"
                  name="dropOffDate"
                  {...register2("dropOffDate")}
                  showTime={false}
                  maxDate={new Date(2090, 0, 1)}
                  dateFormat="yy/mm/dd"
                  control={control2}
                  isError={errors2["dropOffDate"]}
                  errorMsg={getFormErrorMessage("dropOffDate")}
                  label="Dropoff Date"
                  rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                />
              </Grid>
              <Grid item xs={2}>
                <EzControlledDropDowntWithFloatingLabel
                  name="dropOffTime"
                  control={control2}
                  rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                  optionLabel={"name"}
                  optionValue={"id"}
                  options={hourslist}
                  isError={errors2['dropOffTime']}
                  errorMsg={getFormErrorMessage('dropOffTime')}
                  label="Dropoff Time"
                  labelClassName={"ml-2"}
                />
              </Grid>


              <Grid item xs={2}>
                <EzControlledDropDowntWithFloatingLabel
                  name="driverAge"
                  control={control2}
                  rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                  optionLabel={"name"}
                  optionValue={"id"}
                  options={driverageDD}
                  isError={errors2['driverAge']}
                  errorMsg={getFormErrorMessage('driverAge')}
                  label="Driver's Age"
                  labelClassName={"ml-2"}
                />
              </Grid>
              <Grid item xs={3}>
                <div style={{ margin: "0px 0px 0px 10px", maxWidth: "100px" }}>
                  <Button
                    type="button"
                    label={"Search"}
                    className="mt-2 p-button-raised"
                    onClick={handleSubmit2(onSubmit2)}
                  />
                </div>
              </Grid>
            </Grid>
          </form>
          {/* {JSON.stringify(formFieldValueMap)} */}
          {other !== undefined && Object.keys(other).length > 0 && (

 
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">

            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography className="headbgblue" variant="h6">
                  Selected Vehicle Information

                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <Box>
                      <img className="imgbox_renter" src={other?.imageURL} />
                    </Box>
                    <p className="vantype">{other?.categoryTitle}</p>
                    <Box className="vandetails">
                      <ul>
                        <li>
                          <Diversity3Icon />
                          <span>{other?.passenger}</span>
                        </li>
                        <li>
                          <BusinessCenterIcon />
                          <span>{other?.luggage}</span>
                        </li>
                        <li>
                          <ManageAccountsIcon />
                          <span>{other?.transmission}</span>
                        </li>
                        <li>
                          <img src={CardICON} />
                          <span>{other?.doors}</span>
                        </li>
                        <li>
                          <HeatPumpIcon />
                          <span>{other?.ac}</span>
                        </li>
                        <li>
                          <SpeedIcon />
                          <span>LTD</span>
                        </li>

                      </ul>
                    </Box>



                  </Grid>
                  <Grid item xs={7} className="boxmidright">
                    <Grid container spacing={2}>
                      {paymentOption.map((item, index) => (
                        <Grid position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                          <input
                            checked={item.isChecked}
                            type="radio"
                            onChange={(e) => handleRadio(e, index)}
                            name="payOption"
                            value={item.value}
                          />
                          {item.name}
                          <span className="discount">
                            {item.name === "Pay Now" ? "Save $" + item?.discount : "Pay at Pickup"}
                          </span>
                          <span className="valueoption">
                            ${item.value}
                          </span>
                        </Grid>
                      ))}


                    </Grid>

                    <table className="tbleforRenter">
                      <tbody>
                       
                          <tr className="rowfont">
                            <td colSpan={2}>Before Taxes</td>
                            <td className="textr">${other?.beforeTaxAmount}</td>
                          </tr>


                          <tr className="rowfont">
                            <td colSpan={3}>{other?.days}</td>
                          </tr>
                          <tr className="rowfont">
                            <td colSpan={2}>Pay now discount</td>
                            <td className="textr">${other?.payNowDiscount}</td>
                          </tr>
                          <tr className="rowfont">
                            <td colSpan={2}>Extras <span className="expandicon" onClick={handleExtra}>Expand <ArrowDownwardIcon /></span></td>
                            <td className="textr">${other?.extrasAmount || extraamountTotal}</td>
                            
                          </tr>
                          {extraBoll > 0 && (
                          <tr>
                            <td colSpan={3}>
                             <table className="subtable">
                              <tr>
                                <td>Damage Protection Plans</td>
                                <td>
                                  {damage.filter(option => option.isChecked).map(option => option?.title)}
                                </td>
                                <td className="textr"> $ {damageTotal}</td>
                              </tr>
                              <tr>
                                <td>Additional Driver(s)</td>
                                <td>
                                  {additionalDriver.filter(option => option.isChecked).map(option => option?.title)}
                                </td>
                                <td className="textr"> $ {additionalDriverTotal}</td>
                              </tr>
                              <tr>
                                <td>Glass & Tires</td>
                                <td>
                                  {glassTires.filter(option => option.isChecked).map(option => option?.title)}
                                </td>
                                <td className="textr"> $ {glassTiresTotal}</td>
                              </tr>
                              <tr>
                                <td>Equipment</td>
                                <td>
                                  {equipment.filter(option => option.isChecked).map(option => option?.title)}
                                </td>
                                <td className="textr"> $ {equipmentTotal}</td>
                              </tr>
                              <tr>
                                <td>Out of Province</td>
                                <td>
                                  {outofProvince.filter(option => option.isChecked).map(option => option?.title)}
                                </td>
                                <td className="textr"> $ {outofProvinceTotal}</td>
                              </tr>
                              <tr>
                                <td>Extra Mileage</td>
                                <td>
                                  {extraMileage.filter(option => option.isChecked).map(option => option?.title)}
                                </td>
                                <td className="textr"> $ {extraMileageTotal}</td>
                              </tr>
                             </table>
                            </td>
                          </tr>)}
                          <tr className="rowfont">
                            <td colSpan={2}>Fees</td>
                            <td className="textr">${other?.fee.toFixed(2)}</td>
                          </tr>
                          <tr className="rowfont">
                            <td colSpan={2}>Airport Facility Charge Total for {other?.airportFacilityChargeTotalForDays}</td>
                            <td className="textr">${other?.beforeTaxAmount}</td>
                          </tr>
                          <tr className="rowfont">
                            <td colSpan={2}>Concession Fee</td>
                            <td className="textr">${other?.concessionFee}</td>
                          </tr>

                          <tr className="rowfont">
                            <td colSpan={2}>After Hour Fee</td>
                            <td className="textr">${other?.afterHourFee}</td>
                          </tr>
 <tr className="rowfont">
                            <td colSpan={2}>Estimated Taxes</td>
                            <td className="textr">${other?.beforeTaxAmount}</td>
                          </tr>

                        <tr className="rowfont fontsize">
                          <td colSpan={2}>Total Amount (in {other?.currencyCode}): ${amountTotal})</td>
                          <td className="textr">${amountTotal}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="totalamoutstikey">Total Amount (in {other?.currencyCode}): ${amountTotal})</div>
                  </Grid>
                  <Grid container spacing={2}>
                  <Grid item xs={6} >
                  <div className="boxloop">
                   
                      <Typography className="heading">Damage Protection PlansReconsider?</Typography>
                      {damage && damage.map((item, index) => (
                        <Grid className="listbox" position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                          <div className="name"  >
                            <label for={"damage_" + item.id}>
                              {item.title}</label>
                          </div>
                          <div className="value">
                            ${item.amount}
                          </div>
                          <div className="radiobox">
                            <input
                              id={"damage_" + item.id}
                              checked={item.isChecked}
                              type="radio"
                              onChange={(e) => handleRadio(e, index)}
                              name="damage"
                              value={item.amount}
                            /></div>
                        </Grid>
                      ))}

                   
                  </div>
                  <div className="boxloop">
                
                      <Typography className="heading">Additional Driver(s)</Typography>
                      {additionalDriver && additionalDriver.map((item, index) => (
                        <Grid className="listbox" position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                          <div className="name">
                            <label for={"additionalDriver_" + item.id}>
                              {item.title}</label>
                          </div>
                          <div className="value">
                            ${item.amount}
                          </div>
                          <div className="radiobox">
                            <input
                              id={"additionalDriver_" + item.id}
                              checked={item.isChecked}
                              type="radio"
                              onChange={(e) => handleRadio(e, index)}
                              name="additionalDriver"
                              value={item.amount}
                            /></div>
                        </Grid>
                      ))}

                   
                  </div>
                  <div className="boxloop">
                  
                   <Typography className="heading">Glass & Tires</Typography>
                   {glassTires && glassTires.map((item, index) => (
                     <Grid className="listbox" position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                       <div className="name">
                         <label for={"glassTires_" + item.id}>
                           {item.title}</label>
                       </div>
                       <div className="value">
                         ${item.amount}
                       </div>
                       <div className="radiobox">
                         <input
                           id={"glassTires_" + item.id}
                           checked={item.isChecked}
                           type="radio"
                           onChange={(e) => handleRadio(e, index)}
                           name="glassTires"
                           value={item.amount}
                         /></div>
                     </Grid>
                   ))}

                
               </div>
               <div className="boxloop">
             
                   <Typography className="heading">Equipment</Typography>
                   {equipment && equipment.map((item, index) => (
                     <Grid className="listbox" position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                       <div className="name">
                         <label for={"equipment_" + item.id}>
                           {item.title}</label>
                       </div>
                       <div className="value">
                         ${item.amount}
                       </div>
                       <div className="radiobox">
                         <input
                           id={"equipment_" + item.id}
                           checked={item.isChecked}
                           type="checkbox"
                           onChange={(e) => handleRadio(e, index)}
                           name="equipment"
                           value={item.amount}
                         /></div>
                     </Grid>
                   ))}

                
               </div>
               
               </Grid>
               <Grid item xs={6} >
               <div className="boxloop">
               
                   <Typography className="heading">Out of Province</Typography>
                   {outofProvince && outofProvince.map((item, index) => (
                     <Grid className="listbox" position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                       <div className="name">
                         <label for={"outofProvince_" + item.id}>
                           {item.title}</label>
                       </div>
                       <div className="value">
                         ${item.amount}
                       </div>
                       <div className="radiobox">
                         <input
                           id={"outofProvince_" + item.id}
                           checked={item.isChecked}
                           type="radio"
                           onChange={(e) => handleRadio(e, index)}
                           name="outofProvince"
                           value={item.amount}
                         /></div>
                     </Grid>
                   ))}

                
               </div>

               <div className="boxloop">
             
                   <Typography className="heading">Extra Mileage</Typography>
                   {extraMileage && extraMileage.map((item, index) => (
                     <Grid className="listbox" position={"relative"} key={"idx" + index} item xs={12} alignItems={"left"} textAlign={"left"}>
                       <div className="name">
                         <label for={"extraMileage_" + item.id}>
                           {item.title}</label>
                       </div>
                       <div className="value">
                         ${item.amount}
                       </div>
                       <div className="radiobox">
                         <input
                           id={"extraMileage_" + item.id}
                           checked={item.isChecked}
                           type="radio"
                           onChange={(e) => handleRadio(e, index)}
                           name="extraMileage"
                           value={item.amount}
                         /></div>
                     </Grid>
                   ))}

                
               </div>
              
               </Grid>
                  </Grid>
                 
                  
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography className="headbgblue" variant="h6">
                  Driver & Payment Information

                </Typography>
                <Grid container spacing={2} marginTop={"30px"}>
                  {basicDetail?.map((item) => (
                    item.name === "status" ? (
                      <Grid item xs={12} key={item.name}>
                        <EzControlledDropDowntWithFloatingLabel
                          name={item?.name}
                          control={control}
                          rules={item?.rule}
                          optionLabel={"name"}
                          optionValue={"id"}
                          options={statusDD}
                          isError={errors[item?.name]}
                          errorMsg={getFormErrorMessage(item?.name)}
                          label={item?.label}
                          labelClassName={"ml-2"}
                        />
                      </Grid>
                    ) :

                      item.type === "date" ? (
                        <Grid item xs={12} key={item.name}>
                          <EzControlledCalendarWithFloatingLabel
                            id={item.name}
                            name={item.name}
                            {...register(item.name)}
                            showTime={false}
                            maxDate={new Date(2090, 0, 1)}
                            dateFormat="yy/mm/dd"
                            control={control}
                            isError={errors[item.name]}
                            errorMsg={getFormErrorMessage(item.name)}
                            label={item.label}
                            rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
                          />
                        </Grid>
                      ):item.type === "checkbox" ? (
                          <Grid item xs={12} key={item.name}>
                            <Typography className="copycard">
                            Car Rental Company Rules & Restrictions (e.g., insurance, driver age, mileage and geographic restrictions, shuttle info and additional charges)
                            </Typography>
                           <EzControlledCheckBoxWithLabel
                              name={item.name}
                              value={inclusiveRate}
                              id={item.name}
                              control={control}
                              isError={errors[item.label]}
                              label={item.label}
                              labelClassName={'ml-2'}
                              onChange={handleCheckbox}
                            />
                          </Grid>
                        ):item.box === "half" ? (
                          <Grid item xs={6} key={item.name}>
                          <EzControlledInputWithFloatingLabel
                            name={item?.name}
                            control={control}
                            label={item?.label}
                            type={item?.type}
                            rules={item?.rule}
                            isError={errors[item?.name]}
                            errorMsg={getFormErrorMessage(item?.name)}
                          />
                        </Grid>
                        )



                          : (
                            (<Grid item xs={12} key={item.name}>
                              <EzControlledInputWithFloatingLabel
                                name={item?.name}
                                control={control}
                                label={item?.label}
                                type={item?.type}
                                rules={item?.rule}
                                isError={errors[item?.name]}
                                errorMsg={getFormErrorMessage(item?.name)}
                              />
                            </Grid>))
                  ))}

                </Grid>
                <div className={"make-flex mt-3 mr-2"}>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>



                <React.Fragment>
                  <div style={{ margin: "15px 0px 15px 10px", minWidth: "100px" }}>
                    <Button
                      type="submit"
                      label={isEdit ? "Update" : "Save"}
                      className="mt-2 p-button-raised"
                    />
                  </div>

                </React.Fragment>

              </div>
            </div>
              </Grid>
            </Grid>


          
            {isEdit && (<Grid className="modsection" container spacing={2}>
              {editMode?.map((item) => (
                <Grid item xs={3} key={item.name}>
                  <EzControlledInputWithFloatingLabel
                    name={item?.name}
                    control={control}
                    disabled={item?.disabled}
                    label={item?.label}
                    type={item?.type}
                    rules={item?.rule}
                    isError={errors[item?.name]}
                    errorMsg={getFormErrorMessage(item?.name)}
                  />
                </Grid>
              )
              )
              }
            </Grid>)
            }
          </form>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CreateReservationsReservationsComponent;
