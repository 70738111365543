//import { post, put } from "axios";
import axios from 'axios';
import { getApiAsyn, baseUrl, getApiAsynarrayBuffer, deleteApi } from "../../../PostAPI";
import { handleAPIError, APIError } from "../../../common/errorHandler";
import { API_URL } from "../../../../utils/constant";
import { extractData, formatDatesHyphan } from "../../../../utils";
import { decorateWithPaginationParamsPost, decorateWithSortParamsPost, getDecoratedUrl } from "../../../common/urlService";

export const getPayloadInfo = (obj, key) => {
  if (!obj || typeof obj !== 'object') return undefined;
  return obj[key];
};



const { post, put, get } = axios;
export const fetchReservationsReservations1 = async (payload) => {
  console.log("ASDBKS", payload)
  const url = getDecoratedUrl({
    url: API_URL.RESERVATIONS_RESERVATIONS.GET,
    payload,
  });
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};



export const fetchReservationsReservations = async (payload) => {
  console.log("RATE",payload);
  console.log("additinalFilter",payload?.additinalFilter);
  const url = getDecoratedUrl({
    url: API_URL.RESERVATIONS_RESERVATIONS.GET,
    payload,
  });
  let sortdata = decorateWithSortParamsPost(payload);
  if(sortdata===""){
    sortdata="id,desc"
  }
 
   let startDate = formatDatesHyphan(getPayloadInfo(payload?.additinalFilter, "startDate"));
   console.log("ALA",startDate)
   let locationId  = getPayloadInfo(payload?.additinalFilter, "location");
   let networkId  = getPayloadInfo(payload?.additinalFilter, "network");
   let status  = getPayloadInfo(payload?.additinalFilter, "status");
   let type  = getPayloadInfo(payload?.additinalFilter, "type");
   let endDate  = formatDatesHyphan(getPayloadInfo(payload?.additinalFilter, "endDate"));
   let pageno = decorateWithPaginationParamsPost(payload);
  let payloads={
    paged: true,
    pageNumber: pageno?.pageNumber,
    pageSize: pageno?.pageSize,
    userId:localStorage.getItem("userId"),
    sort: sortdata,
    startDate: startDate + " 00:00:00" ?? "",
    endDate: endDate + " 00:00:00"?? "",
    locationId: (locationId ?? "") === "" ? [0] : locationId,
    networkId: (networkId ?? "") === "" ? [0] : networkId,
    status: (status ?? "") === "" ? 0 : status,
    type: type ?? "",
  }
  try {
    //let result = await getApiAsyn(url, localStorage.getItem("token"));
    let result = await dataUploadApi(
      `${API_URL.RESERVATIONS_RESERVATIONS.GET}`,
      payloads
    );
    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const fetchReservationsReservationsDropdown = async (payload) => {
  const url = API_URL.RESERVATIONS_RESERVATIONS.DROPDOWN;
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    return result; //extractData(result, !payload?.filters);
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};



export const multipartDataUploadImage = (url, payload) => {
 
  const formPayload = new FormData();
  formPayload.append("file", payload?.file[0]);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: localStorage.getItem("token"),
    },
  };
  return post(baseUrl + url, formPayload, config);
};



export const upladImageData = async (payload) => {
  const url = API_URL.UPLOAD_IMAGE.replace(
    ":id",
    payload.id
  );
  try {
    let result = await multipartDataUploadImage(
      url,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
const dataUploadApi = (url, data, isEdit = false) => {
  // const formPayload = new FormData();
  // // data part of multipart data
  // const json = JSON.stringify(data);
  // const blobData = new Blob([json], {
  //   type: 'application/json',
  // });
  // formPayload.append('data', blobData);

  // // file part of multipart data
  // files?.forEach((file) => {
  //   formPayload.append('file', file);
  // });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: localStorage.getItem("token"),
    },
  };

  if (isEdit) {
    return post(baseUrl + url, data, config);
  } else {
    return post(baseUrl + url, data, config);
  }
};
export const createReservationsReservations = async (payload) => {
  try {
    let result = await dataUploadApi(
      `${API_URL.RESERVATIONS_RESERVATIONS.CREATE}`,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};

export const editReservationsReservations = async (payload) => {
  const url = API_URL.RESERVATIONS_RESERVATIONS.EDIT.replace(":id", payload?.id);

  try {
    let result = await dataUploadApi(url, payload.data, true);

    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};





const dataExportApi = (url, data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      authorization: localStorage.getItem("token"),
    },
  };
    return get(baseUrl + url, data, config);
};

export const exportReservationsReservationsRecord = async (payload) => {
  const url = API_URL.RESERVATIONS_RESERVATIONS.EXPORT;
  try {
    let result = await getApiAsynarrayBuffer(url, localStorage.getItem("token"));
    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};




export const fetchReservationsReservationsById = async (payload) => {
  const url = API_URL.RESERVATIONS_RESERVATIONS.GET_BY_ID.replace(
    ":id",
    payload?.id
  );
  try {
    let result = await getApiAsyn(url, localStorage.getItem("token"));

    if (result?.error) {
      throw new APIError(result);
    }

    result = extractData(result, !payload?.filters);

    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};
export const deleteReservationsReservations = async (payload) => {
  try {
     const url = API_URL.RESERVATIONS_RESERVATIONS.DELETE.replace(
      ":id",
      payload?.id
    );

      let result = await deleteApi(url, localStorage.getItem("token"));
    if (result?.error) {
      throw new APIError(result);
    }
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};  


export const searchReservationsReservations = async (payload) => {
  try {
    let result = await dataUploadApi(
      `${API_URL.RESERVATIONS_RESERVATIONS.SEARCH}`,
      payload
    );

    if (result?.error) {
      throw new APIError(result);
    }
    result = extractData(result, !payload?.filters);
    return result;
  } catch (error) {
    console.error(error);
    return handleAPIError(error);
  }
};