import { useLocation } from "react-router-dom";

export const getEmbeddedContentFromResponse = (_embedded) => {
    return _embedded?.content;
  };
  
  export const extractData = (result, isEmbeddedContent = true) => {
    
    let modifiedResult = {
      ...result,
      content: getEmbeddedContentFromResponse(result?._embedded),
    };
    delete modifiedResult._embedded;
    return modifiedResult;
  };
  export const useQuery = ()=> {
    return new URLSearchParams(useLocation().search);
  }
  export const removeWordAfterAt = (str)=> {
    let parts = str.split('@');
    if (parts.length <= 1 || parts[1] === "") {
      return str;
    }
    return parts[0];
  }

  export const formatDates = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  };
  export const formatDatesHyphan = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  export const formatDatesMMDDYYYYY = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('/');
  };