
export const basicDetail =
[
  {
    name:"firstName",
     "rule": {
        "required": true
      },
    label:"First Name",
    type:"text",
    box:"half"
  },
  {
    name:"lastName",
     "rule": {
        "required": false
      },
    label:"Last Name",
    type:"text",
    box:"half"
  },
  {
    name:"emailId",
     "rule": {
        "required": true
      },
    label:"Email ID",
    type:"text",
    box:"half"
  },
  {
    name:"phoneNumber",
     "rule": {
        "required": true
      },
    label:"Phone Number",
    type:"text",
    box:"half"
  },
  {
    name:"address",
     "rule": {
        "required": true
      },
    label:"Address",
    type:"text"
  },
  {
    name:"flightDetails",
     "rule": {
        "required": false
      },
    label:"Flight Details/Special Instructions",
    type:"text"
  },
  {
    name:"nameOnCard",
     "rule": {
        "required": false
      },
    label:"Name on Card",
    type:"text"
  },
  {
    name:"creditCardNumber",
     "rule": {
        "required": false
      },
    label:"Credit Card Number",
    type:"text"
  },
  {
    name:"cvv",
     "rule": {
        "required": false
      },
    label:"CVV",
    type:"text",
    box:"half"
  },
  {
    name:"cardExpiry",
     "rule": {
        "required": false
      },
    label:"Card Expiry MM/YY",
    type:"text",
    box:"half"
  },
  {
    name:"terms",
     "rule": {
        "required": true
      },
    label:"I agree to all the terms & conditions",
    type:"checkbox"
  },
]

export const statusDD =
[
  { id:1, name:"Active"},
  { id:0, name:"Inactive"}
]
export const typeDD =
[
  { id:1, name:"Flat"},
  { id:2, name:"Percentage"}
]
export const bookingTypeDD =
[
  { id:"bookingDate", name:" Booking date"},
  { id:"pickDate", name:"Pickup date"},
  { id:"returnDate", name:"Return date"}
]


export const listStatusDD =
[
  { id:0, name:"All"},
  { id:1, name:"Confirmed"},
  { id:2, name:"Modified"},
  { id:3, name:"No Show"},
  { id:4, name:"Cancelled"},
  { id:5, name:"Unconfirmed"}
]
export const driverageDD =
[
  { id:1, name:"23-24"},
  { id:2, name:"25 and over"},
 
]
export const editMode =
  [
    {
      name:"modTime",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod Time",
      type:"text"
    },
    {
      name:"modBy",
      disabled:true,
       "rule": {
          "required": false
        },
      label:"Mod By",
      type:"text"
    }
  ]
  export const ReservationsReservationsBreadcrumb = [
    {label:"Dashboard", path:"/dashboard"},
    {label:"Reservations", path:"/reservations"},
    {label:"Reservations Detail Report", path:"/reservations-detail-report"},
    {label:"Daily Manifest Report", path:"/daily-manifest-report"},
    {label:"Bookings by Location Report ", path:"/bookings-by-location-report"},
    {label:"Prepaid Bookings Report", path:"/prepaid-bookings-report"},
    {label:"CRX/Rezp Reservations", path:"/crx-rezp-reservations"},
    {label:"", path:"#"},
]


export const datagetbyid={
 
  Other:[
    {id:1, name:"Before Taxes", value:126.48},
    {id:2, name:"3 Day(s) @ 81.6", value:0},
    {id:3, name:"Pay now discount", value:-6.32},
    {id:4, name:"Extras", value:0},
    {id:5, name:"Fees", value:15.69},
    {id:6, name:"Airport Facility Charge Total for 3", value:6},
    {id:7, name:"Concession Fee", value:19.73},
    {id:8, name:"After Hour Fee", value:0},
    {id:5, name:"Estimated Taxes", value:8.40},
  ],

  PaymentOption:[
    {id:1, name:"Pay Now", value:120.16, discount:6.32},
    {id:1, name:"Pay Later", value:126.48, discount:0 }
],
  DamageProtectionPlansReconsider:[
  {id:"1", name:"None", value:0.00},
  {id:"2", name:"$250 Deductible (Ontario locations)", value:89.97},
  {id:"3", name:"$500 Deductible", value:83.97},
  {id:"4", name:"$1,000 Deductible", value:68.97},
  {id:"5", name:"Gold Card Surcharge", value:27},
  {id:"6", name:"12 Passenger/Cargo ($2500 Deductible)) ", value:77.97},
  {id:"7", name:"Convertible ($3000 Deductible)", value:89.97},
  {id:"8", name:"International/G2/Underage Driver ($2500 Deductible)", value:77.97},
  {id:"9", name:"International/G2/Underage Driver ($1500 Deductible)-Ontario locations", value:89.97},
  {id:"10", name:"12 Passenger/Cargo ($1500 Deductible) ", value:98.97},
  {id:"11", name:"LDW (YUKON)- $5000 DEDUCTIBLE ", value:150}
  ],
  
  AdditionalDriver:[
  {id:"1", name:"None", value:0.00},
  {id:"2", name:"Additional Driver", value:30.00},
  ],
  GlassTires:[
  {id:"1", name:"None", value:0.00},
  {id:"2", name:"Glass & Tire Coverage", value:29.97},
  ],
  Equipment:[
  {id:"1", name:"GPS", value:30},
  {id:"2", name:"Forward-facing child car seat", value:30},
  {id:"3", name:"Rear Facing Infant Seat", value:30},
  {id:"4", name:"Booster Child Seat", value:21},
  ],
  OutofProvince:[
  {id:"1", name:"None", value:0},
  {id:"2", name:"Border Crossing Fee USA", value:30},
  {id:"3", name:"Out of Province", value:15},
  {id:"4", name:"ep", value:28.5}
  ],
  ExtraMileage:[
  {id:"1", name:"None", value:0},
  {id:"2", name:"Extra 100 Km", value:54},
  {id:"3", name:"Extra 200 Km", value:40.00},
  {id:"4", name:"Extra 300 Km", value:28.5},
  {id:"5", name:"Extra 500 Km", value:100.00},
  {id:"6", name:"Extra 1000 Km", value:200.00},
  {id:"7", name:"Extra 2000 Km", value:400.00},
  ]
  }