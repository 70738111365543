import React, { useEffect, useState } from 'react'
import companyData from "./tableData.json";
import { connect } from "react-redux";
import { useNavigate  } from "react-router-dom";
import {
  fetchRentersRegisteredViewByIDRenters,
    initializeRentersRegisteredRentersScreen,
    setDeleteConfirmationPopupVisibility,
    exportRentersRegisteredRenters
  } from "../../../../redux/actions/Renters/RentersRegisteredRenters";
import RentersRegisteredRentersView from '../../../../component/Renters/RentersRegisteredRenters/ViewRentersRegisteredRenters';
import { generatePath } from 'react-router-dom';
import { loadingShow } from '../../../../services/PostAPI';
import { APP_URLS } from '../../../../utils/constant';
import { useParams } from "react-router-dom";
function RentersRegisteredRentersViewScreen(props) {
    const {
      fetchRentersRegisteredViewByIDRenters,
        initRentersRegisteredRentersScreen,
        showPageLevelLoader,
        RentersRegisteredRentersListviewByID,
        paginationInfo,
        exportRentersRegisteredRenters,
        isRentersRegisteredRentersExportError,
        isRentersRegisteredRentersExportSuccess,
        isLoading,
      } = props;
      const { id } = useParams();
const navigate = useNavigate ();
      useEffect(() => {
        initRentersRegisteredRentersScreen();
        fetchRentersRegisteredViewByIDRenters({id:id, sortField: 'id',userId:localStorage.getItem("userId") });
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
     
      const loadLazyData = (props) => {
        let newProps={
          id:id,
          ...props,
          userId:localStorage.getItem("userId")
        }
        fetchRentersRegisteredViewByIDRenters(newProps);
      };
      const handleCreateRentersRegisteredRenters =() =>{
      navigate(APP_URLS.RENTERS_REGISTEREDRENTERS.CREATE);
    }
      const editRentersRegisteredRentersRoute = (id) => {
        const path = generatePath(APP_URLS.RENTERS_REGISTEREDRENTERS.EDIT, {
          id,
        });
        navigate(path);
      };
const RentersRegisteredRentersProps = {
  handleCreateRentersRegisteredRenters,
  exportRentersRegisteredRenters,
        isRentersRegisteredRentersExportError,
        isRentersRegisteredRentersExportSuccess,
  editRentersRegisteredRentersRoute,
  ...companyData,
  screenPermissions:companyData?.screenPermissions,
  setDeleteConfirmationPopupVisibility,
  fetchRentersRegisteredViewByIDRenters: loadLazyData,
  ...companyData,
  tableData: {
    columns: RentersRegisteredRentersListviewByID?.columnss,
    rows: RentersRegisteredRentersListviewByID?.rowss,
  },
  paginationInfo: {
    pageSize: paginationInfo?.pageSize,
    pageNumber: paginationInfo?.pageNumber,
    totalPages: paginationInfo?.totalPages,
    totalRecords: paginationInfo?.totalRecords,
  },
  isLoading,
};

if (showPageLevelLoader) {
  loadingShow("block");
  return null;
}
loadingShow("none");
  return (
    <div>
    <RentersRegisteredRentersView
    {...RentersRegisteredRentersProps}
    />
  </div>
  )
}


const mapDispatchToProps = (dispatch) => {
    return {
      initRentersRegisteredRentersScreen: () => dispatch(initializeRentersRegisteredRentersScreen()),
      fetchRentersRegisteredViewByIDRenters: (payload) => dispatch(fetchRentersRegisteredViewByIDRenters(payload)),
      setDeleteConfirmationPopupVisibility: (payload) =>
        dispatch(setDeleteConfirmationPopupVisibility(payload)),
      exportRentersRegisteredRenters: (payload) => dispatch(exportRentersRegisteredRenters(payload)),
     };
  };
  

  //vehicleCagegoryReducer
  const mapStateToProps = (state, ownProps) => {
    return {

      isRentersRegisteredRentersCreateSuccess: selectFromRentersRegisteredRentersReducer(
        state,
        "isRentersRegisteredRentersCreateSuccess"
      ),
      RentersRegisteredRentersListviewByID: selectFromRentersRegisteredRentersReducer(
        state,
        "RentersRegisteredRentersListviewByID"
      ),
      showPageLevelLoader: selectFromRentersRegisteredRentersReducer(
        state,
        "showPageLevelLoader"
      ),
      paginationInfo: selectFromRentersRegisteredRentersReducer(
        state,
        "paginationInfo"
      ),
      isPageLevelError: selectFromRentersRegisteredRentersReducer(
        state,
        "isPageLevelError"
      ),
      isFetchingGridData: selectFromRentersRegisteredRentersReducer(
        state,
        "isFetchingGridData"
      ),
      isRentersRegisteredRentersExportError: selectFromRentersRegisteredRentersReducer(
        state,
        "isRentersRegisteredRentersExportError"
      ),
      isRentersRegisteredRentersExportSuccess: selectFromRentersRegisteredRentersReducer(
        state,
        "isRentersRegisteredRentersExportSuccess"
      ),
      ...ownProps,
    };
  };

const selectFromRentersRegisteredRentersReducer = (state, path) => {
  return selectRentersRegisteredRentersReducer(state)[path];
};

const selectRentersRegisteredRentersReducer = ({ RentersRegisteredRentersReducer }) => {
  return RentersRegisteredRentersReducer;
};


export default connect(mapStateToProps, mapDispatchToProps)(RentersRegisteredRentersViewScreen);
