

export const FETCH_RESERVATIONS_RESERVATIONS_LIST = {
    START: 'FETCH_RESERVATIONS_RESERVATIONS_LIST_START',
    SUCCESS: 'FETCH_RESERVATIONS_RESERVATIONS_LIST_SUCCESS',
    ERROR: 'FETCH_RESERVATIONS_RESERVATIONS_LIST_ERROR',
  };
  export const TOAST = {
    RESET: 'RESET_TOAST',
  };
  export const FETCH_RESERVATIONS_RESERVATIONS_INIT="FETCH_RESERVATIONS_RESERVATIONS_INIT"
  export const SET_DELETE_CONFIRMATION_POPUP_VISIBILITY =
  'SET_DELETE_CONFIRMATION_POPUP_VISIBILITY';


  
export const INIT_CREATE_RESERVATIONS_RESERVATIONS_SCREEN =
'INIT_CREATE_RESERVATIONS_RESERVATIONS_SCREEN';

export const CREATE_RESERVATIONS_RESERVATIONS = {
START: 'CREATE_RESERVATIONS_RESERVATIONS_START',
SUCCESS: 'CREATE_RESERVATIONS_RESERVATIONS_SUCCESS',
ERROR: 'CREATE_RESERVATIONS_RESERVATIONS_ERROR',
};

export const EDIT_RESERVATIONS_RESERVATIONS = {
START: 'EDIT_RESERVATIONS_RESERVATIONS_START',
SUCCESS: 'EDIT_RESERVATIONS_RESERVATIONS_SUCCESS',
ERROR: 'EDIT_RESERVATIONS_RESERVATIONS_ERROR',
};

export const FETCH_RESERVATIONS_RESERVATIONS_DETAIL = {
  START: 'FETCH_RESERVATIONS_RESERVATIONS_DETAIL_START',
  SUCCESS: 'FETCH_RESERVATIONS_RESERVATIONS_DETAIL_SUCCESS',
  ERROR: 'FETCH_RESERVATIONS_RESERVATIONS_DETAIL_ERROR',
};
export const EXPORT_RESERVATIONS_RESERVATIONS = {
  START: 'EXPORT_RESERVATIONS_RESERVATIONS_START',
  SUCCESS: 'EXPORT_RESERVATIONS_RESERVATIONS_SUCCESS',
  ERROR: 'EXPORT_RESERVATIONS_RESERVATIONS_ERROR',
};
export const RESERVATIONS_RESERVATIONSDD = {
  START: 'RESERVATIONS_RESERVATIONSDD_START',
  SUCCESS: 'RESERVATIONS_RESERVATIONSDD_SUCCESS',
  ERROR: 'RESERVATIONS_RESERVATIONSDD_ERROR',
};
export const RESERVATIONS_RESERVATIONSDELETE = {
  START: 'RESERVATIONS_RESERVATIONSDELETE_START',
  SUCCESS: 'RESERVATIONS_RESERVATIONSDELETE_SUCCESS',
  ERROR: 'RESERVATIONS_RESERVATIONSDELETE_ERROR',
};

export const SEARCH_RESERVATIONS_RESERVATIONS = {
  START: 'SEARCH_RESERVATIONS_RESERVATIONS_START',
  SUCCESS: 'SEARCH_RESERVATIONS_RESERVATIONS_SUCCESS',
  ERROR: 'SEARCH_RESERVATIONS_RESERVATIONS_ERROR',
  };