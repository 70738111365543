import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
 import { ReservationsReservationsServices } from "../../../../services";
import { goBack } from "../../../../utils/gotoback";
import { UPLOAD_IMAGE } from "../../../types/uploadimage.type";
import {
  FETCH_RESERVATIONS_RESERVATIONS_INIT, 
  FETCH_RESERVATIONS_RESERVATIONS_LIST, 
  SET_DELETE_CONFIRMATION_POPUP_VISIBILITY, 
  INIT_CREATE_RESERVATIONS_RESERVATIONS_SCREEN,
  CREATE_RESERVATIONS_RESERVATIONS,
  EDIT_RESERVATIONS_RESERVATIONS,
  TOAST,
  FETCH_RESERVATIONS_RESERVATIONS_DETAIL,
  EXPORT_RESERVATIONS_RESERVATIONS,
  RESERVATIONS_RESERVATIONSDD,
  RESERVATIONS_RESERVATIONSDELETE,
  SEARCH_RESERVATIONS_RESERVATIONS
} from "../../../types/Reservations/Reservations.type";
export const initializeReservationsReservationsScreen = () => ({ type: FETCH_RESERVATIONS_RESERVATIONS_INIT });

export const ReservationsReservationsFetchStart = (payload) => ({
  type: FETCH_RESERVATIONS_RESERVATIONS_LIST.START,
  payload,
});

export const ReservationsReservationsFetchSuccess = (payload) => ({
  type:FETCH_RESERVATIONS_RESERVATIONS_LIST.SUCCESS,
  payload,
});

export const ReservationsReservationsFetchError = (payload) => ({
  type: FETCH_RESERVATIONS_RESERVATIONS_LIST.ERROR,
  payload,
});

export const fetchReservationsReservations = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsReservationsFetchStart(payload));
    ReservationsReservationsServices.fetchReservationsReservations(payload).then((data) => {
      if (!data.isError) {
        dispatch(ReservationsReservationsFetchSuccess(data));
      } else {
        dispatch(ReservationsReservationsFetchError(data));
      }
    });
  };
};



// DD
export const ReservationsReservationsDDFetchStart = (payload) => ({
  type: RESERVATIONS_RESERVATIONSDD.START,
  payload,
});

export const ReservationsReservationsDDFetchSuccess = (payload) => ({
  type:RESERVATIONS_RESERVATIONSDD.SUCCESS,
  payload,
});

export const ReservationsReservationsDDFetchError = (payload) => ({
  type: RESERVATIONS_RESERVATIONSDD.ERROR,
  payload,
});

export const fetchReservationsReservationsDD = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsReservationsDDFetchStart(payload));
    ReservationsReservationsServices.fetchReservationsReservationsDropdown(payload).then((data) => {
      if (!data.isError) {
        dispatch(ReservationsReservationsDDFetchSuccess(data));
      } else {
        dispatch(ReservationsReservationsDDFetchError(data));
      }
    });
  };
};

//END DD

export const setDeleteConfirmationPopupVisibility = (payload) => ({
  type: SET_DELETE_CONFIRMATION_POPUP_VISIBILITY,
  payload,
});

export const ReservationsReservationsDetailFetchStart = (payload) => ({
  type: FETCH_RESERVATIONS_RESERVATIONS_DETAIL.START,
  payload,
});

export const ReservationsReservationsDetailFetchSuccess = (payload) => ({
  type: FETCH_RESERVATIONS_RESERVATIONS_DETAIL.SUCCESS,
  payload,
});

export const ReservationsReservationsDetailFetchError = (payload) => ({
  type: FETCH_RESERVATIONS_RESERVATIONS_DETAIL.ERROR,
  payload,
});

export const fetchReservationsReservationsById = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsReservationsDetailFetchStart(payload));

    ReservationsReservationsServices
      .fetchReservationsReservationsById(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(ReservationsReservationsDetailFetchSuccess(data));
        } else {
          dispatch(ReservationsReservationsDetailFetchError(data));
        }
      });
  };
};

export const uploadImageStart = () => ({
  type: UPLOAD_IMAGE.START,
});

export const uploadImageSuccess = (payload) => ({
  type: UPLOAD_IMAGE.SUCCESS,
  payload,
});

export const uploadImageError = (payload) => ({
  type: UPLOAD_IMAGE.ERROR,
  payload,
});

export const uploadImage = (payload) => {
  return (dispatch) => {
    dispatch(uploadImageStart());
    ReservationsReservationsServices.upladImageData(payload).then((data) => {
      if (!data.isError) {
        dispatch(uploadImageSuccess(data));
      } else {
        dispatch(uploadImageError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
/*---------------End Upload Image------------------------*/
export const createReservationsReservationsScreenInitStart = (payload) => ({
  type: INIT_CREATE_RESERVATIONS_RESERVATIONS_SCREEN,
  payload,
});

export const initCreateReservationsReservationsScreen = (payload) => {
  return (dispatch) => {
    dispatch(createReservationsReservationsScreenInitStart());
  };
};


export const createReservationsReservationsStart = () => ({
  type: CREATE_RESERVATIONS_RESERVATIONS.START,
});

export const createReservationsReservationsSuccess = (payload) => ({
  type: CREATE_RESERVATIONS_RESERVATIONS.SUCCESS,
  payload,
});

export const createReservationsReservationsError = (payload) => ({
  type: CREATE_RESERVATIONS_RESERVATIONS.ERROR,
  payload,
});

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createReservationsReservations = (payload) => {
  return (dispatch) => {
    dispatch(createReservationsReservationsStart());
    ReservationsReservationsServices.createReservationsReservations(payload).then((data) => {
      if (!data.isError) {
        dispatch(createReservationsReservationsSuccess());
        goBack();
      } else {
        dispatch(createReservationsReservationsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const editReservationsReservationsStart = (payload) => ({
  type: EDIT_RESERVATIONS_RESERVATIONS.START,
  payload
});

export const editReservationsReservationsSuccess = (payload) => ({
  type: EDIT_RESERVATIONS_RESERVATIONS.SUCCESS,
  payload,
});

export const editReservationsReservationsError = (payload) => ({
  type: EDIT_RESERVATIONS_RESERVATIONS.ERROR,
  payload,
});

export const editReservationsReservations = (payload) => {
  return (dispatch) => {
    dispatch(editReservationsReservationsStart());
    ReservationsReservationsServices.editReservationsReservations(payload).then((data) => {
      if (!data.isError) {
        dispatch(editReservationsReservationsSuccess());
        goBack();
      } else {
        dispatch(editReservationsReservationsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};

export const exportReservationsReservationsRecordStart = (payload) => ({
  type: EXPORT_RESERVATIONS_RESERVATIONS.START,
  payload
});

export const exportReservationsReservationsRecordSuccess = (payload) => ({
  type: EXPORT_RESERVATIONS_RESERVATIONS.SUCCESS,
  payload,
});

export const exportReservationsReservationsRecordError = (payload) => ({
  type: EXPORT_RESERVATIONS_RESERVATIONS.ERROR,
  payload,
});

export const exportReservationsReservations = (payload) => {
  return (dispatch) => {
    dispatch(exportReservationsReservationsRecordStart());
    ReservationsReservationsServices.exportReservationsReservationsRecord(payload).then((data) => {
      if (!data.isError) {
        dispatch(exportReservationsReservationsRecordSuccess(data));
      } else {
        dispatch(exportReservationsReservationsRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};


/*---------------START RATE MIRRORS DELETE------------------*/

export const ReservationsReservationsDeleteStart = (payload) => ({
  type: RESERVATIONS_RESERVATIONSDELETE.START,
  payload,
});

export const ReservationsReservationsDeleteSuccess = (payload) => ({
  type: RESERVATIONS_RESERVATIONSDELETE.SUCCESS,
  payload,
});

export const ReservationsReservationsDeleteError = (payload) => ({
  type: RESERVATIONS_RESERVATIONSDELETE.ERROR,
  payload,
});

export const deleteReservationsReservations = (payload) => {
  return (dispatch) => {
    dispatch(ReservationsReservationsDeleteStart(payload));

    ReservationsReservationsServices
      .deleteReservationsReservations(payload)
      .then((data) => {
        if (!data.isError) {
          dispatch(ReservationsReservationsDeleteSuccess(data));
          dispatch(fetchReservationsReservations(payload?.params));
        } else {
          dispatch(ReservationsReservationsDeleteError(data));
        }
      });
  };
};

/*----------------END----------------------------*/



// Search
export const searchReservationsReservationsStart = () => ({
  type: SEARCH_RESERVATIONS_RESERVATIONS.START,
});

export const searchReservationsReservationsSuccess = (payload) => ({
  type: SEARCH_RESERVATIONS_RESERVATIONS.SUCCESS,
  payload,
});

export const searchReservationsReservationsError = (payload) => ({
  type: SEARCH_RESERVATIONS_RESERVATIONS.ERROR,
  payload,
});


export const searchReservationsReservations = (payload) => {
  return (dispatch) => {
    dispatch(searchReservationsReservationsStart());
    ReservationsReservationsServices.searchReservationsReservations(payload).then((data) => {
      if (!data.isError) {
        dispatch(searchReservationsReservationsSuccess(data));
      } else {
        dispatch(searchReservationsReservationsError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};
